<template>
    <b-card title="Packing Stickers">
        <b-form>
            <b-row>
                <b-col md="3">
                        <b-form-group label="Order No / Barcode">
                            <b-form-input placeholder="Please Enter Order No / Barcode"></b-form-input>
                        </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Due Date">
                        <flat-pickr class="form-control" name="date" placeholder="Please Enter Due Date"/>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-button variant="dark" class="mt-2 mr-2">
                        <feather-icon icon="RefreshCwIcon" class="mr-50" />
                        Refresh
                    </b-button>
                    <b-button variant="primary" class="mt-2 mr-2">Print Sticker</b-button>
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col md="5">
                    <b-button variant="secondary" disabled>120</b-button>
                    <div>
                        <!-- table -->
                        <vue-good-table :columns="columns" :rows="rows" :rtl="direction" :search-options="{
                        enabled: true,
                        externalQuery: searchTerm }" :select-options="{
                          enabled: true,
                          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                          selectionInfoClass: 'custom-class',
                          selectionText: 'rows selected',
                          clearSelectionText: 'clear',
                          disableSelectInfo: true, // disable the select info panel on top
                          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                        }" :pagination-options="{
                          enabled: true,
                          perPage:pageLength
                        }">
                            <template slot="table-row" slot-scope="props">

                                <!-- Column: Name -->
                                <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                                    <b-avatar :src="props.row.avatar" class="mx-1" />
                                    <span class="text-nowrap">{{ props.row.fullName }}</span>
                                </span>

                                <!-- Column: Status -->
                                <span v-else-if="props.column.field === 'status'">
                                    <b-badge :variant="statusVariant(props.row.status)">
                                        {{ props.row.status }}
                                    </b-badge>
                                </span>


                                <!-- Column: Common -->
                                <span v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </span>
                            </template>

                            <!-- pagination -->
                            <template slot="pagination-bottom" slot-scope="props">
                                <div class="d-flex justify-content-between flex-wrap">
                                    <div class="d-flex align-items-center mb-0 mt-1">
                                        <span class="text-nowrap ">
                                            Showing 1 to
                                        </span>
                                        <b-form-select v-model="pageLength" :options="['10', '20', '50','100']" class="mx-1"
                                            @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                                    </div>
                                    <div>
                                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength"
                                            first-number last-number align="right" prev-class="prev-item"
                                            next-class="next-item" class="mt-1 mb-0"
                                            @input="(value)=>props.pageChanged({currentPage:value})">
                                            <template #prev-text>
                                                <feather-icon icon="ChevronLeftIcon" size="18" />
                                            </template>
                                            <template #next-text>
                                                <feather-icon icon="ChevronRightIcon" size="18" />
                                            </template>
                                        </b-pagination>
                                    </div>
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                </b-col>
                <b-col md="2" class="text-center">
                    <b-button block variant="primary" class="mt-1 mr-1">
                        <feather-icon icon="ChevronRightIcon" class="mr-50" />
                    </b-button>
                    <b-button block variant="primary" class="mt-1 mr-1">
                        <feather-icon icon="ChevronLeftIcon" class="mr-50" />
                    </b-button>
                    <b-button block variant="primary" class="mt-1 mr-1">
                        <feather-icon icon="ChevronsRightIcon" class="mr-50" />
                    </b-button>
                    <b-button block variant="primary" class="mt-1 mr-1">
                        <feather-icon icon="ChevronsLeftIcon" class="mr-50" />
                    </b-button>
                </b-col>

                <b-col md="5" class="text-right">
                    <b-button variant="secondary" disabled>0</b-button>
                    <div>
                        <!-- table -->
                        <vue-good-table :columns="columns" :rows="rows" :rtl="direction" :search-options="{
                        enabled: true,
                        externalQuery: searchTerm }" :select-options="{
                          enabled: true,
                          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                          selectionInfoClass: 'custom-class',
                          selectionText: 'rows selected',
                          clearSelectionText: 'clear',
                          disableSelectInfo: true, // disable the select info panel on top
                          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                        }" :pagination-options="{
                          enabled: true,
                          perPage:pageLength
                        }">
                            <template slot="table-row" slot-scope="props">

                                <!-- Column: Name -->
                                <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                                    <b-avatar :src="props.row.avatar" class="mx-1" />
                                    <span class="text-nowrap">{{ props.row.fullName }}</span>
                                </span>

                                <!-- Column: Status -->
                                <span v-else-if="props.column.field === 'status'">
                                    <b-badge :variant="statusVariant(props.row.status)">
                                        {{ props.row.status }}
                                    </b-badge>
                                </span>



                                <!-- Column: Common -->
                                <span v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </span>
                            </template>

                            <!-- pagination -->
                            <template slot="pagination-bottom" slot-scope="props">
                                <div class="d-flex justify-content-between flex-wrap">
                                    <div class="d-flex align-items-center mb-0 mt-1">
                                        <span class="text-nowrap ">
                                            Showing 1 to
                                        </span>
                                        <b-form-select v-model="pageLength" :options="['3','5','10']" class="mx-1"
                                            @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                                    </div>
                                    <div>
                                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength"
                                            first-number last-number align="right" prev-class="prev-item"
                                            next-class="next-item" class="mt-1 mb-0"
                                            @input="(value)=>props.pageChanged({currentPage:value})">
                                            <template #prev-text>
                                                <feather-icon icon="ChevronLeftIcon" size="18" />
                                            </template>
                                            <template #next-text>
                                                <feather-icon icon="ChevronRightIcon" size="18" />
                                            </template>
                                        </b-pagination>
                                    </div>
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                </b-col>
            </b-row>

        </b-form>
    </b-card>
</template>
  
<script>
import {
    BAvatar, BCard, BRow, BCol, BForm, BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
export default {
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem, BCard, BRow, BCol, BForm, BButton,
        vSelect,flatPickr

    },
    data() {
        return {
            pageLength: 5,
            dir: false,
            columns: [
                {
                    label: 'Order',
                    field: 'order',
                },
                {
                    label: 'Due Date',
                    field: 'dueDate',
                },
                {
                    label: 'Barcode',
                    field: 'barcode',
                },
                {
                    label: 'Customer',
                    field: 'customer',
                },
                {
                    label: 'Garment',
                    field: 'garment',
                },
                {
                    label: 'Service',
                    field: 'service',
                },
            ],
            rows: [],
            searchTerm: '',
            orderTypeOption: ['Laundry & Dry Cleaning', 'Curtain Cleaning', 'Carpet Cleaning']
        }
    },
    computed: {
    },
    created() {
        this.$http.get('/good-table/basic')
            .then(res => { this.rows = res.data })
    },
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
   